<template>
	<div>
		<main-header></main-header>
		<main-content></main-content>
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import MainContent from '../components/MainContent.vue';
import MainHeader from '../components/MainHeader.vue';

export default {
	components: { MainContent, Footer, MainHeader },
};
</script>

<style></style>
